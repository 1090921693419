.CameraError{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;

    .bg{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        height: 100%;
    }

    .logo{
        top: 30px;
        width: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .content{
        position: absolute;
        width: 80%;
        top: calc(50% + 50px);
        transform: translate(-50%, -50%);
        left: 50%;

        &.android{
            width: 55%;
            top: calc(50% + 80px);
        }
        img{
            width: 100%;
            pointer-events: all;
        }
    }
}