.ARUI{
    pointer-events: all;
    *{
        user-select: none;
    }

    .logo{
        top: 30px;
        width: 50%;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
    }

    .black-overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        font-family: 'TCCC-UnityHeadline';
        text-align: center;
        align-items: center;
        transition: opacity 300ms;
        display: flex !important;
        justify-content: center;
        &.visible{
            pointer-events: all;
            opacity: 1;
        }
        &.hidden{
            opacity: 0;
            pointer-events: none;
        }
    }
    .close-btn{
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .download-hint{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        color: white;
        font-size: 20px;
        margin: 0;
        pointer-events: none;
        user-select: none;
        transition: opacity 300ms;
    }

    .helpUI{
        display: flex;
        flex-direction: column;

        position: absolute;
        left: 31px;
        right: 31px;
        top: 100px;
        bottom: 140px;

        background: radial-gradient(100% 359.18% at 0% 0%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(15px);
        border-radius: 20px;

        padding: 30px 10px;
        opacity: 1;
        transition: opacity 300ms;
        p{
            font-family: 'TCCC-UnityHeadline';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;

            color: #FFFFFF;
        }

        &.show{
            opacity: 1;
        }

        &.hide{
            opacity: 0;
        }

        .marker-wrapper{
            margin-top: 20px;
            height: 200px;
            img{
                opacity: 0;
                position: absolute;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .btn, .capture-button{
        pointer-events: auto;
        opacity: 0.8;
        img{
            width: 100%;
            pointer-events: none;
        }
    }

    .btn{
        position: absolute;
        bottom: 15px;
        width: 40px;
        height: 40px;
        opacity: 0;
    }

    .help-button{
        left: 15px;
    }

    .recenter-button{
        right: 15px;
    }

    .swap-camera-button{
        right: 30px;
        bottom: 25px;
        width: 60px;
        height: 60px;
    }

    .capture-button{
        width: 70px;
        height: 70px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 25px;
        opacity: 0;

        .rec{
            position: absolute;
            width: 100%;
            width: 100%;
            top: 0;
            left: 0;
            transition: opacity 200ms;
        }
    }

    .startingSoon{
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        visibility: hidden;
        opacity: 0;

        .bg{
            min-height: 100vh;
            min-width: 100vw;
            position: absolute;
            z-index: 0;
        }

        .logo, h1{
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
        }

        h1{
            width: 100%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-family: 'TCCC-UnityHeadline';
            font-style: normal;
            font-weight: 800;
            font-size: 33px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }

    .endScreen{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .logo{
            left: 0;
            top: 30px;
            width: 50%;
            position: relative;
            transform: none;

        }

        .yameiiEnd{
            top: 50px;
            width: 80%;
            // height: calc(80% - 120px);
            // width: auto;
            z-index: 0;
            position: relative;
        }

        .btn-group{
            position: absolute;
            transform: translateX(-50%);
            bottom: 50px;
            left: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 13px;
        }
        p{
            font-family: 'TCCC-UnityHeadline';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            margin: 0;

            color: #FFFFFF;
        }

        .shareUI{
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 12px;
            top: 30px;
            width: calc(100% - 24px);
            height: calc(100% - 60px);
            background-color: rgba(23, 23, 23, 0.7);
            border-radius: 34px;
            padding: 30px 30px 20px 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            .share-close-btn{
                pointer-events: all;
                position: absolute;
                top: 5px;
                right: 5px;
            }

            .preview{
                aspect-ratio: 9/16;
                height: calc(90% - 100px);
            }
        }
    }
    button span{
        font-size: marker-wrapper;
    }
}