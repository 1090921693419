@font-face {
  font-family: 'TCCC-UnityHeadline';
  src: url('/assets/fonts/TCCC-UnityHeadline-Regular.ttf') format('truetype'),
      url('/assets/fonts/TCCC-UnityHeadline-Regular.eot'),
      url('./assets/fonts/TCCC-UnityHeadline-Regular.woff') format('woff'),
      url('./assets/fonts/TCCC-UnityHeadline-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TCCC-UnityText';
  src: url('./assets/fonts/TCCC-UnityText-Black.ttf') format('truetype'),
      url('./assets/fonts/TCCC-UnityText-Black.eot'),
      url('./assets/fonts/TCCC-UnityText-Black.woff') format('woff'),
      url('./assets/fonts/TCCC-UnityText-Black.woff2') format('woff2');
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

html, body{
  width: 100vw;
  height: 100vh;
  position: fixed;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
}

img{
  user-select: none;
}

*{
  box-sizing: border-box;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
