@mixin full-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Desktop{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    .bg{
        @include full-screen;
    }
    .main{
        padding: 30px;
        height: 100vmin;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}