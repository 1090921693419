.UI {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;

//   display: none; //!TEMP

  width: 100%;
  height: 100%;

  overflow: hidden;
  z-index: 3;

  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 0;
    // position: absolute;
    // transform: translate(-50%, -50%);
    // top: 50%;
    // left: 50%;
    // height: 100%;
    // z-index: 0;
  }

  section{
      width: 100%;
      height: 100%;
  }

  .App-logo {
      height: 40vmin;
      pointer-events: none;
      img{
        width: 100%;
      }
  }

  @media (prefers-reduced-motion: no-preference) {
      .App-logo {
          animation: App-logo-spin infinite 20s linear;
      }
  }

  button{
    position: relative;
    background: transparent;
    border: none;
    width: 277px;
    height: 50px;
    pointer-events: all;
    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        // pointer-events: none;
        // user-select: none;
    }
    span{
        position: relative;
        font-family: 'TCCC-UnityText';
        font-weight: bold;
        color: white;
        pointer-events: none;
        user-select: none;
        font-size: 15px;
        white-space: nowrap;
    }
  }

  .App-header {
      background-color: transparent;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
  }

  .App-link {
      color: #61dafb;
  }

  @keyframes App-logo-spin {
      from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }
  }
}