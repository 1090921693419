.OrientationError{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: black;
    pointer-events: all;

    display: none;
    &.show{
        display: block;
    }
    
    .content{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        align-items: flex-start;

        .block{
            width: 50%;
            height: 100%;
            overflow: hidden;
        }

        .left-block{
            text-align: left;
            padding-top: calc(100vh * 0.12);
            padding-left: 12%;
            // display: flex;
            // flex-direction: column;
        }

        .logo{
            position: relative;
            display: block;
            left: -10px;
        }
        .copy{
            text-align: left;
            display: inline-block;
            width: min-content;

            p{
                font-family: 'ITCFranklinGothic';
                font-style: normal;
                font-weight: 800;
                font-size: 61px;
                line-height: 100%;
                /* or 50px */
                text-align: left;
                text-transform: uppercase;
                color: #FFFFFF;
                margin: 0;
                padding: 0;
                // display: inline-block;
                display: flex;
                align-items: baseline;
                justify-content: space-between;

                img{
                    flex-grow: 0.2;
                }

                &.fine_print{
                    font-weight: 600;
                    font-size: 10.1842px;
                    line-height: 10px;
                    letter-spacing: 0.2px;
                }
            }
        }
    }
    @media screen and (max-height: 345px) {
        .bg{
            .layer2{
                height: 310px;
                top: 60%;
            }
        }
        p:not(.fine_print){
             font-size: 45px !important;
        }

        .logo{
            display: block;
        }

        .right-block.block img{
            height: 460px !important;
            margin-top: -40px;
        }
    }

    @media screen and (max-height: 270px) {
        p:not(.fine_print){
             font-size: 34px !important;
             img{
                flex-grow: 0.1 !important;
             }
        }
        .logo{
            display: block;
        }
        .fine_print{
            font-size: 8.9842px !important;
        }


    }
}