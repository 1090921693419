.Landing{
    background-color: rgba($color: #000000, $alpha: 1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 30px 0px;
    height: 100%;
    // padding: 30px;
    // position: relative;

    .logo{
        // position: absolute;
        // transform: translateX(-50%);
        // top: 20px;
        // left: 50%;
        width: 50%;
        z-index: 0;
        flex: 0 0 auto;
    }

    .group{
        flex: 1 1 auto;
        position: relative;
        width: 90%;


        .wrapper{
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            padding-bottom: 70px;

            .yameii_body{
                min-height: 100%;
                z-index: 1;
            }

            .yameii_base{
                z-index: 0;
                position: relative;
                transform: translateY(-40%);
                // top: 100%;
                left: 0;
                width: 100%;

                // animation-name: pulse;
                // animation-duration: 0.7s;
                // animation-timing-function: ease-in;
                // animation-delay: 0s;
                // animation-iteration-count: infinite;
                // animation-direction: alternate;

            }
        }
    }
    button{
        flex: 0 0 auto;
        pointer-events: auto;
    }

    @media only screen
    and (min-device-width: 820px)
    and (min-device-height: 1180px)
    and (-webkit-min-device-pixel-ratio: 1) {

    }

    @keyframes pulse{
        from {transform: translateY(-40%) scale(1)}
        to {transform: translateY(-40%) scale(0.95)}
    }
}