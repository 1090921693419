.BreadCrumb{
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 20px 20px 0px 20px;
    width: 100%;
    height: 70px;
    z-index: inherit;
    transition: opacity 300ms, top 300ms;
    &.hide{
        top: -20px;
        opacity: 0;
    }
    &.show{
        top: 0px;
        opacity: 1;
    }
    .bg{
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.6;
    }
    .container{
        z-index: 1;
        position: absolute;
        width: calc(100% - 40px);
        height: calc(100% - 20px);
        top: 20px;
        left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img{
            height: 100%;
            width: auto;
            flex: 0 0 auto;
        }
        .divider{
            flex: 1 1 auto;
            margin: 0 10px;
            background-color: transparent;
            border: 1px dashed white;
            height: 1px;
        }

        &.wired{
            // mix-blend-mode: screen;
            .divider{
                border: none;
            }
            img{
                opacity: 1;
                transition: opacity 300ms;
                &.collected{
                    opacity: 0;
                }
            }
        }

        &.color{
            img{
                opacity: 0;
                transition: opacity 300ms;
                &.collected{
                    opacity: 1;
                }
            }
        }
    }
}