.Loading {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 800;
    background-color: black;
    overflow: hidden;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        min-width: 100vw;
        z-index: 0;
    }

    .wrapper{
        width: 100%;
        height: 100%;
        padding: 30px 0px;
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        *{
            position: relative;
        }

        h1{
            color: white;
            font-family: 'TCCC-UnityHeadline';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            text-align: center;
            letter-spacing: -0.04em;
        }

        .logo{

            width: 50%;
        }

    }

    ._8thwall_logo {
        z-index: 2;
        width: 219px;

        -webkit-font-smoothing: antialiased;
        filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.37));
        -webkit-filter: drop-shadow( 1px 1px 0px rgba(0, 0, 0, 0.37) );
    }
}
